// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-program-template-js": () => import("/Users/fernandopulsa/_vault/_work/clientes/Maru/The Beta Way/the-beta-way/src/templates/program-template.js" /* webpackChunkName: "component---src-templates-program-template-js" */),
  "component---src-pages-about-us-js": () => import("/Users/fernandopulsa/_vault/_work/clientes/Maru/The Beta Way/the-beta-way/src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-js": () => import("/Users/fernandopulsa/_vault/_work/clientes/Maru/The Beta Way/the-beta-way/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/Users/fernandopulsa/_vault/_work/clientes/Maru/The Beta Way/the-beta-way/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-press-js": () => import("/Users/fernandopulsa/_vault/_work/clientes/Maru/The Beta Way/the-beta-way/src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-process-js": () => import("/Users/fernandopulsa/_vault/_work/clientes/Maru/The Beta Way/the-beta-way/src/pages/process.js" /* webpackChunkName: "component---src-pages-process-js" */)
}

